import LoginFrom from "../components/forms/LoginForm";

const LoginPage = () =>
{
    return(
        <div className="loginPage">
            
        <LoginFrom/>
        </div>
    )
}

export default LoginPage;