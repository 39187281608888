
const Loading = () =>
{
    return (
        <div className="loading" style={{display:'flex',alignContent:'center',alignItems:'center',justifyContent:'center',height:'90vh'}} >
            <div className="loader"></div>
        </div>
    )
}

export default Loading;